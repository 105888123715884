import { initializeApp } from "firebase/app";
const app = initializeApp(
    {
        apiKey: `${process.env.REACT_APP_FS_CONFIG_API_KEY}`,
        authDomain: `${process.env.REACT_APP_FS_CONFIG_AUTH_DOMAIN}`,
        databaseURL: `${process.env.REACT_APP_FS_CONFIG_DATABASE_URL}`,
        projectId: `${process.env.REACT_APP_FS_CONFIG_PROJECT_ID}`,
        storageBucket: `${process.env.REACT_APP_FS_CONFIG_STORAGE_BUCKET}`,
        messagingSenderId: `${process.env.REACT_APP_FS_CONFIG_MESSAGING_SENDER_ID}`,
        appId: `${process.env.REACT_APP_FS_CONFIG_APP_ID}`,
        measurementId: `${process.env.REACT_APP_FS_CONFIG_MEASUREMENT_ID}`
    }
)
export default app;