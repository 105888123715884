import React from 'react';

function PrivacyPolicy() {
    return (

        <section className="content-area">
            <div className="top_site_main" style={{color: '#fff', backgroundImage: 'url(../wp-content/uploads/2016/07/1.jpg)'}}>
                <div className="page-title-wrapper">
                    <div className="banner-wrapper container">
                        <h1>Polityka prywatności</h1>
                    </div>
                </div>
            </div>
            <div className="breadcrumbs-wrapper">
                <div className="container">
                    <ul itemProp="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList" id="breadcrumbs" className="breadcrumbs">
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <meta itemProp="position" content="1"/>
                            <a
          hrefLang='pl' itemProp="item" href="../index.html" title="Home"><span itemProp="name">Home</span></a>
                        </li>
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <meta itemProp="position" content="2"/>
                            <span itemProp="name" title="Polityka prywatności"> Polityka
                  prywatności</span></li>
                    </ul>
                </div>
            </div>
            <div className="container site-content no-padding-top">
                <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                        <article id="post-6082" className="post-6082 page type-page status-publish hentry">
                            <div className="entry-content">
                                <br/>
                                <br/>
                                <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Drodzy
                          Państwo,</span></span></span></p>
                                <p style={{textAlign: 'justify'}}><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Od
                          25 maja 2018 roku obowiązuje Rozporządzenie Parlamentu
                          Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
                          sprawie ochrony osób fizycznych w związku z
                          przetwarzaniem danych osobowych i w sprawie swobodnego
                          przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                          (określane jako „RODO”, „ORODO”,
                          „GDPR” lub „Ogólne Rozporządzenie
                          o Ochronie Danych”).</span></span></span></p>
                  <p style={{textAlign: 'left'}}><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Ze
                          względu na zmiany w przepisach dotyczących ochrony
                          danych osobowych, przedstawiam zaktualizowane informacje w
                          zakresie przetwarzania Państwa danych przez Biuro
                          Tłumaczeń ARTIST</span></span><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>&nbsp;</span></span></span>
                  </p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Przypominamy, że
                          administratorem Państwa danych jest:</span></span></span>
                  </p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}><b>Biuro Tłumaczeń
                            i Szkoła Języków Obcych ARTIST z siedziba
                            39-200 Dębica , ul. Wielopolska 54.</b></span></span></span></p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Mogą się
                          Państwo kontaktować z nami pod
                          adresem:</span></span></span></p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}><b>39-200 Dębica , ul
                            Wielopolska 54.</b></span></span></span></p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>lub
                          telefonicznie pod numerem: </span></span><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}><b>146765225
                          </b></span></span></span></p>
                  <p style={{textAlign: 'justify'}}><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Od
                          25 maja 2018 roku przysługuje Państwu prawo
                          dostępu do danych, ich poprawiania, żądania ich
                          usunięcia, ograniczenia przetwarzania oraz prawo do
                          przenoszenia danych.&nbsp;</span></span></span></p>
                  <p style={{textAlign: 'justify'}}><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Dane Państwa
                          przetwarzane są wyłącznie w celach
                          realizacji&nbsp;usług tłumaczeniowych oraz w celu
                          wystawiania dla Państwa faktur VAT za nasze
                          usługi.</span></span></span></p>
                  <p style={{textAlign: 'justify'}}><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Państwa dane
                          przetwarzamy na podstawie art. 6 ust. 1 lit. b
                          Rozporządzenia 2016/679 Parlamentu Europejskiego i Rady z
                          dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                          fizycznych w związku z przetwarzaniem danych osobowych i w
                          sprawie swobodnego przepływu takich danych oraz uchylenia
                          dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
                          danych) – dalej: RODO. W ramach świadczonych
                          usług przez nas, podstawą naszego działania jest
                          art. 6 ust. 1 lit. b i c RODO.</span></span></span></p>
                  <p style={{textAlign: 'justify'}}><a name="_GoBack" /> <span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Jeżeli wyraziliście
                          lub wyrazicie Państwo zgodę na otrzymywanie
                          komunikatów marketingowych również na
                          Państwa adres e-mail, to podstawą prawną jest
                          także art. 10 ustawy o świadczeniu usług
                          drogą elektroniczną oraz art. 172 ustawy Prawo
                          telekomunikacyjne.</span></span></span></p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Państwa dane
                          przetwarzamy przez czas:</span></span></span></p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Trwania&nbsp;usługi
                          tłumaczeniowej,&nbsp; niezbędny dla realizacji
                          tłumaczenia</span></span><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>&nbsp;</span></span></span>
                  </p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Państwa dane mogą
                          być przekazane upoważnionym pracownikom; podmiotom
                          świadczącymi wybrane usługi na rzecz Biura
                          (informatyczne, tłumaczeniowe&nbsp;itp.) w takim zakresie,
                          w jakim jest to niezbędne do świadczenia naszych
                          usług.&nbsp;</span></span></span></p>
                  <p><span style={{color: '#000000'}}>&nbsp;</span></p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Informacje
                          szczegółowe:</span></span></span></p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Państwa
                          uprawnienia:</span></span></span></p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Stosowany skrót:&nbsp;
                          RODO</span></span></span></p>
                  <p><span style={{color: '#000000'}}>–</span></p>
                  <p style={{textAlign: 'justify'}}><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Rozporządzenie
                          Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
                          2016 r. w sprawie ochrony osób fizycznych w związku
                          z przetwarzaniem danych osobowych i w sprawie swobodnego
                          przepływu takich danych oraz uchylenia dyrektywy
                          95/46/WE</span></span></span></p>
                  <p><span style={{color: '#000000'}}>&nbsp;<span style={{fontFamily: 'Times New Roman, serif'}}><span style={{fontFamily: 'Arial, serif'}}>Zgodnie z RODO
                          przysługują Państwu następujące
                          uprawnienia realizowane przez nasze</span></span></span></p>
                  <ol>
                    <li>
                      <p align="JUSTIFY"><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>dostępu do
                              treści Państwa danych osobowych&nbsp;(art. 15
                              RODO) przez żądanie
                              udostępnienia</span></span> <span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>lub przesłania
                              kopii kompletu Państwa danych osobowych
                              przetwarzanych przez nasze biuro;</span></span></span>
                      </p>
                    </li>
                    <li>
                      <p align="JUSTIFY"><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>żądania
                              sprostowania Państwa danych osobowych (art. 16
                              RODO) przez wskazanie nieprawidłowych danych&nbsp;
                              osobowych, które Państwa
                              dotyczą</span></span></span></p>
                    </li>
                    <li><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>żądania
                            usunięcia Państwa danych osobowych (art. 17 RODO)
                            ;</span></span></span></li>
                    <li><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>żądania
                            ograniczenia przetwarzania Państwa danych osobowych
                            (art. 18 RODO) przez wskazanie, które dane powinny
                            zostać ograniczone w zakresie
                            przetwarzania</span></span></span></li>
                    <li><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>do przenoszenia&nbsp;
                            Państwa danych osobowych (art. 20 RODO) przez
                            żądanie przygotowania&nbsp; i przekazania przez
                            biuro danych osobowych przekazanych przez&nbsp; Państwa
                            do innego administratora</span></span></span></li>
                    <li><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>w ustrukturyzowanym,
                            powszechnie używanym formacie nadającym się
                            do odczytu maszynowego;</span></span></span></li>
                    <li><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>wniesienia sprzeciwu
                            wobec przetwarzania danych osobowych opartego na art. 6
                            ust.1 lit. e) lub f) RODO, z przyczyn związanych z
                            Państwa szczególną
                            sytuacją</span></span></span></li>
                    <li><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>wniesienia skargi do
                            organu nadzorczego&nbsp; w przypadku uznania, że
                            przetwarzanie Państwa danych osobowych narusza
                            przepisy&nbsp; RODO (art. 77 ust. 1
                            RODO)</span></span></span></li>
                  </ol>
                  <p><span style={{color: '#000000'}}>&nbsp;<span style={{fontFamily: 'Times New Roman, serif'}}><span style={{fontFamily: 'Arial, serif'}}>Dobrowolność
                          podania danych:&nbsp; </span></span></span></p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Podanie przez Państwa
                          danych osobowych jest dobrowolne.</span></span></span></p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Podanie przez Państwa
                          danych osobowych&nbsp;jest niezbędne do&nbsp;
                          świadczenia wobec Państwa w/w
                          usług.&nbsp;</span></span><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>&nbsp;</span></span></span>
                  </p>
                  <p><span style={{fontFamily: 'Times New Roman, serif'}}><span style={{color: '#000000'}}><span style={{fontFamily: 'Arial, serif'}}>Zapraszamy do zapoznania
                          się z wdrożoną w biurze Polityką
                          Prywatności i Bezpieczeństwa.</span></span></span></p>
                <hr/>
                <br/>
                <br/>
                </div>
              </article>
            </main>
          </div>
        </div>
      </section>
    );
}

export default PrivacyPolicy;