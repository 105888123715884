import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import School from "./pages/School";
import Contact from "./pages/Contact";
import Footer from "./pages/Footer";
import NoPage from "./pages/NoPage";
import Translations from "./pages/Translations";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import {app} from "./shared/fsConfig";
import {getAnalytics} from "firebase/analytics"
export default function App() {
  getAnalytics(app)
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="index.html" element={<Home />} />
          <Route path="school" element={<School />} />
          <Route path="translations" element={<Translations />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));