import React from 'react';

function Home() {
    return (
        <section className="content-area">
            <div className="top_site_main" style={{color: '#fff', backgroundImage: 'url(../wp-content/uploads/2016/07/1.jpg)'}}>
                <div className="page-title-wrapper">
                    <div className="banner-wrapper container">
                        <h1>404 - Not Found</h1>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Home;