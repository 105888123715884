import React from 'react';

function Translations() {
    return (

        <section className="content-area">
            <div className="top_site_main"
                 style={{color: '#fff', backgroundImage: 'url(../wp-content/uploads/2016/07/1.jpg)'}}>
                <div className="page-title-wrapper">
                    <div className="banner-wrapper container">
                        <h1>Tłumaczenia</h1>
                    </div>
                </div>
            </div>
            <div className="breadcrumbs-wrapper">
                <div className="container">
                    <ul itemProp="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList" id="breadcrumbs"
                        className="breadcrumbs">
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <meta itemProp="position" content="1"/>
                            <a itemProp="item"
          hrefLang='pl' href="https://artistpl.web.app" title="Home"><span itemProp="name">Home</span></a></li>
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <meta itemProp="position" content="2"/>
                            <span itemProp="name" title="Tłumaczenia"> Tłumaczenia</span></li>
                    </ul>
                </div>
            </div>
            <div className="container site-content">
                <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                        <article id="post-5938" className="post-5938 page type-page status-publish hentry">
                            <div className="entry-content">
                                <div id="pl-5938">
                                    <div className="panel-grid" id="pg-5938-0">
                                        <div className="panel-grid-cell" id="pgc-5938-0-0">
                                            <div className="so-panel widget widget_heading panel-first-child"
                                                 id="panel-5938-0-0-0" data-index={0}>
                                                <div className="thim-widget-heading thim-widget-heading-base">
                                                    <div className="sc_heading text-left">
                                                        <h3 className="title">Dla firm</h3><span className="line"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="so-panel widget widget_black-studio-tinymce widget_black_studio_tinymce panel-last-child"
                                                id="panel-5938-0-0-1" data-index={1}>
                                                <div className="textwidget">
                                                    <p style={{textAlign: 'justify'}}>Powierzając nam
                                                        tłumaczenie tekstów korporacyjnych
                                                        zyskujecie Państwo pewność, że
                                                        usługa zostanie wykonana na najwyższym
                                                        poziomie, a uzyskany materiał nie tylko spełni
                                                        Państwa oczekiwania, ale stanie się
                                                        wizytówką profesjonalizmu w biznesie.
                                                        Gwarantujemy terminowość i
                                                        rzetelność oraz zachowanie poufności.<br/>
                                                        Nasza oferta obejmuje tłumaczenia pisemne,
                                                        zwykłe, uwierzytelnione oraz ustne. Cena oraz
                                                        termin realizacji ustalane są indywidualnie –
                                                        w odpowiedzi na Państwa potrzeby i oczekiwania.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-grid-cell" id="pgc-5938-0-1">
                                            <div className="so-panel widget widget_heading panel-first-child"
                                                 id="panel-5938-0-1-0" data-index={2}>
                                                <div className="thim-widget-heading thim-widget-heading-base">
                                                    <div className="sc_heading text-left">
                                                        <h3 className="title">Dla klientów indywidualnych
                                                        </h3><span className="line"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="so-panel widget widget_black-studio-tinymce widget_black_studio_tinymce panel-last-child"
                                                id="panel-5938-0-1-1" data-index={3}>
                                                <div className="textwidget">
                                                    <p style={{textAlign: 'justify'}}>Nieważne czy
                                                        potrzebujesz tłumaczenia samochodowego, czy
                                                        przekładu tekstu naukowego – każde z
                                                        Twoich oczekiwań spełnimy szybko i
                                                        profesjonalnie. Każda objętość
                                                        materiału i każdy rodzaj tekstu traktujemy
                                                        jako wyzwanie i z przyjemnością stawimy mu
                                                        czoła. Jeśli zależy Ci na jakości i
                                                        czasie, nasza oferta jest właśnie dla Ciebie.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-grid" id="pg-5938-1">
                                        <div style={{align: 'center'}} className="panel-row-style">
                                            <div className="panel-grid-cell" id="pgc-5938-1-0">
                                                <div
                                                    className="so-panel widget widget_button panel-first-child panel-last-child"
                                                    id="panel-5938-1-0-0" data-index={4}>
                                                    <div className="thim-widget-button thim-widget-button-base"><a
                                                        className="widget-button  normal custom_style"
                                                        href="mailto:biuro@artist.com.pl"
                                                        style={{fontSize: '14px', fontWeight: 'normal'}}
                                                        data-hover="font-size: 14px;font-weight: normal;"><i
                                                        className="fa fa-envelope-o" style={{fontSize: '14px'}}/> Aby
                                                        uzyskać
                                                        wycenę swojego tłumaczenia prosimy o
                                                        przesłanie pliku lub skanu na adres: <a
                                                            href="mailto:biuro@artist.com.pl">biuro@artist.com.pl</a></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </main>
                </div>
            </div>
        </section>
    );
}

export default Translations;