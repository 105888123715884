import React from 'react';

function School() {
    return (
        <section className="content-area">
            <div className="top_site_main" style={{color: '#fff', backgroundImage: 'url(../wp-content/uploads/2016/07/1.jpg)'}}>
                <div className="page-title-wrapper">
                    <div className="banner-wrapper container">
                        <h1>Szkoła</h1>
                    </div>
                </div>
            </div>
            <div className="breadcrumbs-wrapper">
                <div className="container">
                    <ul itemProp="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList" id="breadcrumbs" className="breadcrumbs">
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <meta itemProp="position" content="1"/>
                            <a itemProp="item"
          hrefLang='pl' href="https://artistpl.web.app" title="Home"><span
                                itemProp="name">Home</span></a></li>
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <meta itemProp="position" content="2"/>
                            <span itemProp="name" title="Szkoła"> Szkoła</span></li>
                    </ul>
                </div>
            </div>
            <div className="container site-content">
                <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                        <article id="post-5945" className="post-5945 page type-page status-publish hentry">
                            <div className="entry-content">
                                <div id="pl-5945">
                                    <div className="panel-grid" id="pg-5945-0">
                                        <div className="panel-grid-cell" id="pgc-5945-0-0">
                                            <div className="so-panel widget widget_heading panel-first-child" id="panel-5945-0-0-0" data-index={0}>
                                                <div className="thim-widget-heading thim-widget-heading-base">
                                                    <div className="sc_heading text-left">
                                                        <h3 className="title">Dla firm</h3><span className="line" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="so-panel widget widget_black-studio-tinymce widget_black_studio_tinymce panel-last-child" id="panel-5945-0-0-1" data-index={1}>
                                                <div className="textwidget">
                                                    <p style={{textAlign: 'justify'}}>Jeśli poszukujecie
                                                        Państwo profesjonalnych lektorów,
                                                        którzy pomogą Waszym pracownikom
                                                        podnieść kompetencje językowe, to nasza
                                                        oferta bez wątpienia spełni Państwa
                                                        oczekiwania. Od wielu lat prowadzimy szkolenia w znanych
                                                        podkarpackich firmach, a grono naszych zadowolonych
                                                        uczniów i pracodawców stale się
                                                        poszerza. Program kursów dostosowujemy
                                                        indywidualnie do potrzeb poszczególnych grup i
                                                        projektujemy go tak, aby był nie tylko efektywny,
                                                        ale i interesujący!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-grid-cell" id="pgc-5945-0-1">
                                            <div className="so-panel widget widget_heading panel-first-child" id="panel-5945-0-1-0" data-index={2}>
                                                <div className="thim-widget-heading thim-widget-heading-base">
                                                    <div className="sc_heading text-left">
                                                        <h3 className="title">Dla klientów indywidualnych
                                                        </h3><span className="line" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="so-panel widget widget_black-studio-tinymce widget_black_studio_tinymce panel-last-child" id="panel-5945-0-1-1" data-index={3}>
                                                <div className="textwidget">
                                                    <p style={{textAlign: 'justify'}}>Przed Tobą ważny
                                                        egzamin lub wyjazd zagraniczny? Chcesz podszlifować
                                                        gramatykę, a może potrzebujesz po prostu
                                                        „dogadać się” w obcym
                                                        języku?<br />
                                                        Rozumiemy, że potrzeby w zakresie nauki języka
                                                        mogą być różne, dlatego
                                                        projektując ofertę naszych kursów
                                                        stawiamy na różnorodność
                                                        zajęć i naukę w małych grupach</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-grid" id="pg-5945-1">
                                        <div className="panel-grid-cell" id="pgc-5945-1-0">
                                            <div className="so-panel widget widget_black-studio-tinymce widget_black_studio_tinymce panel-first-child panel-last-child" id="panel-5945-1-0-0" data-index={4}>
                                                <h3 className="widget-title">Co nas wyróżnia od innych
                                                    firm?</h3>
                                                <div className="textwidget">
                                                    <ul>
                                                        <li>Małe grupy - <strong>nawet 4 osoby</strong>
                                                        </li>
                                                        <li>Odpłatność miesięczna -
                                                            <strong>najniższe ceny w mieście!</strong>
                                                        </li>
                                                        <li>Kursy bez umów</li>
                                                        <li>Cena jednej lekcji od <strong>8,75zł</strong>
                                                            (16 lekcji - 140zł/miesiąc)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-grid-cell" id="pgc-5945-1-1">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </main>
                </div>
            </div>
        </section>
    );
}

export default School;