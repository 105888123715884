import React from 'react';
import depositPhoto from "../shared/img/wp-content/uploads/2018/10/Depositphotos_88216748.jpg";
import unnamedPhoto from "../shared/img/wp-content/uploads/2016/07/Bez-nazwy-1.png";
//Refs
import goodYearRef from "../shared/img/wp-content/uploads/2016/04/goodyear.jpg";
import ref1 from "../shared/img/wp-content/uploads/2016/04/ref1.jpg";
import ref2 from "../shared/img/wp-content/uploads/2016/04/ref2.jpg";
import ref3 from "../shared/img/wp-content/uploads/2016/04/ref3.jpg";
import ref4 from "../shared/img/wp-content/uploads/2016/04/ref4.jpg";
import ref5 from "../shared/img/wp-content/uploads/2016/04/ref5.jpg";
import ref6 from "../shared/img/wp-content/uploads/2016/04/ref6.jpg";
import ref7 from "../shared/img/wp-content/uploads/2016/04/ref7.jpg";
import refSniezka from "../shared/img/wp-content/uploads/2016/04/sniezka.jpg";
import refIkea from "../shared/img/wp-content/uploads/2016/04/referencje-IKEA.jpg";
import refCCI from "../shared/img/wp-content/uploads/2016/07/CCI20160722.png";
import refG2A from "../shared/img/wp-content/uploads/2016/04/G2A-referencje.jpg";
import refRomet from "../shared/img/wp-content/uploads/2019/03/romet.jpg";

//clients
import cl1 from "../shared/img/wp-content/uploads/2016/04/1.png";
import cl2 from "../shared/img/wp-content/uploads/2016/04/2.png";
import cl3 from "../shared/img/wp-content/uploads/2016/04/3.png";
import cl4 from "../shared/img/wp-content/uploads/2016/04/4.png";
import cl6 from "../shared/img/wp-content/uploads/2016/04/6.png";
import cl7 from "../shared/img/wp-content/uploads/2016/04/7.png";
function Home() {
    return (
        <div id="main-home-content" className="home-content home-page container" role="main">
            <div id="pl-5749">
                <div className="panel-grid" id="pg-5749-0">
                    <div className="panel-grid-cell" id="pgc-5749-0-0">
                        <div className="so-panel widget widget_text panel-first-child panel-last-child" id="panel-5749-0-0-0" data-index={0}>
                            <div className="textwidget">
                                <link href="https://fonts.googleapis.com/css?family=Roboto+Slab%3A700" rel="stylesheet" property="stylesheet" type="text/css" media="all" />
                                <div id="rev_slider_3_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="stronaglowna" style={{margin: '0px auto', backgroundColor: 'transparent', padding: '0px', marginTop: '0px', marginBottom: '0px'}}>
                                    <div id="rev_slider_3_1" className="rev_slider fullwidthabanner" style={{display: 'none'}} data-version="5.2.5.4">
                                        <ul>
                                            <li data-index="rs-7" data-transition="fade" data-slotamount="default" data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed={300} data-rotate={0} data-saveperformance="off" data-title="Slide" data-param1 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
                                                <img src="/wp-content/uploads/2018/10/Depositphotos_88216748.jpg" alt="" title="Depositphotos_88216748" width={1920} height={929} data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" className="rev-slidebg" data-no-retina />
                                                <div className="tp-caption   tp-resizeme" id="slide-7-layer-3" data-x={553} data-y={325} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:[-105%];z:0;rX:0deg;rY:0deg;rZ:-90deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power4.easeInOut;" data-transform_out="opacity:0;s:300;" data-mask_in="x:0px;y:0px;s:inherit;e:inherit;" data-start={500} data-splitin="chars" data-splitout="none" data-responsive_offset="on" data-elementdelay="0.1" data-end={9000} style={{zIndex: 5, whiteSpace: 'nowrap', fontSize: '30px', lineHeight: '40px', fontWeight: 700, color: 'rgba(102, 102, 102, 1.00)', fontFamily: 'Roboto Slab'}}>
                                                    Nasza firma funkcjonuje na rynku Dębickim od 2001 roku!
                                                </div>
                                                <div className="tp-caption   tp-resizeme" id="slide-7-layer-4" data-x={555} data-y={396} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;s:1500;e:Power3.easeInOut;" data-transform_out="opacity:0;s:300;" data-mask_in="x:0px;y:0px;s:inherit;e:inherit;" data-start={1000} data-splitin="none" data-splitout="none" data-responsive_offset="on" data-end={9000} style={{zIndex: 6, whiteSpace: 'nowrap', fontSize: '30px', lineHeight: '40px', fontWeight: 700, color: 'rgba(102, 102, 102, 1.00)', fontFamily: 'Roboto Slab'}}>
                                                    Kursy językowe dla osób indywidualnych, firm i
                                                    instytucji <br />– sprawdź nas 2 pierwsze lekcje ZA
                                                    DARMO! </div>
                                                <div className="tp-caption   tp-resizeme" id="slide-7-layer-6" data-x={554} data-y={505} data-width="['auto']" data-height="['auto']" data-transform_idle="o:1;" data-transform_in="y:top;s:2000;e:Power4.easeInOut;" data-transform_out="opacity:0;s:300;" data-start={2000} data-splitin="chars" data-splitout="none" data-responsive_offset="on" data-elementdelay="0.05" data-end={9000} style={{zIndex: 7, whiteSpace: 'nowrap', fontSize: '30px', lineHeight: '40px', fontWeight: 700, color: 'rgba(102, 102, 102, 1.00)', fontFamily: 'Roboto Slab'}}>
                                                    Tłumaczenia we wszystkich językach świata
                                                </div>
                                                <div className="tp-caption   tp-resizeme" id="slide-7-layer-8" data-x={521} data-y={336} data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-transform_idle="o:1;" data-transform_in="opacity:0;s:300;e:Power2.easeInOut;" data-transform_out="opacity:0;s:300;" data-start={500} data-responsive_offset="on" data-end={9000} style={{zIndex: 8}}><img src="/wp-content/uploads/2016/07/Bez-nazwy-1.png" alt="" width={6} height={426} data-ww="5px" data-hh="200px" data-no-retina /> </div>
                                            </li>
                                        </ul>
                                        <div className="tp-bannertimer tp-bottom" style={{visibility: 'hidden !important'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-grid" id="onas">
                    <div className="panel-row-style-thim-welcome-university thim-welcome-university panel-row-style">
                        <div className="panel-grid-cell" id="pgc-5749-onas-0">
                            <div className="so-panel widget widget_heading panel-first-child" id="panel-5749-1-0-0" data-index={1}>
                                <div className="thim-widget-heading thim-widget-heading-base">
                                    <div className="sc_heading text-center">
                                        <h3 className="title">O nas</h3><span className="line" />
                                    </div>
                                </div>
                            </div>
                            <div className="so-panel widget widget_text" id="panel-5749-1-0-1" data-index={2}>
                                <div className="textwidget">
                                    <div className="thim-about-us-quote">
                                        <p>“Z pasją tłumaczymy i szkolimy<br />już od
                                            kilkunastu lat!”</p>
                                    </div>
                                </div>
                            </div>
                            <div className="so-panel widget widget_black-studio-tinymce widget_black_studio_tinymce panel-last-child" id="panel-5749-1-0-2" data-index={3}>
                                <div className="textwidget">
                                    <p style={{textAlign: 'justify'}}>Biuro Tłumaczeń i Szkoła
                                        Języków Obcych Artist istnieje na rynku od 2001 roku. Od
                                        tego czasu nie tylko znacznie rozwinęliśmy naszą
                                        ofertę, ale także zdobyliśmy cenne doświadczenie i
                                        uznanie szerokiego grona klientów indywidualnych i biznesowych.
                                        Wśród naszych propozycji znajdziecie Państwo
                                        tłumaczenia oraz kursy językowe. Każde zlecenie
                                        traktujemy indywidualnie i realizujemy z najwyższą
                                        starannością.</p>
                                    <p style={{textAlign: 'justify'}}>Tłumaczymy wszystko i dla każdego,
                                        we wszystkich popularnych językach nowożytnych!<br />
                                        Szeroka oferta naszego biura tłumaczeń to odpowiedź na
                                        potrzeby zarówno firm jak klientów indywidualnych. Od 2007
                                        roku czerpiemy pasję z przekładu tekstów –
                                        niezależnie od tego czy są to techniczne instrukcje, czy
                                        literatura piękna.&nbsp;W naszej ofercie dostępne są
                                        tłumaczenia z następujących języków:<br />
                                        <strong>ANGIELSKI, NIEMIECKI, FRANCUSKI, HISZPAŃSKI, ROSYJSKI,
                                            WŁOSKI, CZESKI, SŁOWACKI, NORWESKI</strong> oraz wiele
                                        innych.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-grid" id="pg-5749-2">
                    <div className="siteorigin-panels-stretch panel-row-style" style={{minHeight: '560px', backgroundImage: 'url(/wp-content/uploads/2018/10/Depositphotos_84267514.jpg)', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat'}} data-stretch-type="full" data-siteorigin-parallax="{&quot;backgroundUrl&quot;:&quot;\/wp-content\/uploads\/2018\/10\/Depositphotos_84267514.jpg&quot;,&quot;backgroundSize&quot;:[1920,1080],&quot;backgroundSizing&quot;:&quot;scaled&quot;}">
                        <div className="panel-grid-cell" id="pgc-5749-2-0">&nbsp;</div>
                        <div className="panel-grid-cell" id="pgc-5749-2-1">&nbsp;</div>
                    </div>
                </div>
                <div className="panel-grid" id="klienci">
                    <div className="siteorigin-panels-stretch panel-row-style" style={{marginBottom: '-30px', padding: '30px 0px 100px', backgroundColor: '#ffffff'}} data-stretch-type="full">
                        <div className="panel-grid-cell" id="pgc-5749-klienci-0">
                            <div className="so-panel widget widget_heading panel-first-child panel-last-child" id="panel-5749-3-0-0" data-index={4}>
                                <div className="thim-widget-heading thim-widget-heading-base">
                                    <div className="sc_heading text-center">
                                        <h3 className="title">Klienci</h3>
                                        <p className="sub-heading" style={{}}>Wybrane firmy, które
                                            korzystają z naszych usług</p><span className="line" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-grid" id="pg-5749-4">
                    <div className="panel-grid-cell" id="pgc-5749-4-0">
                        <div className="so-panel widget widget_black-studio-tinymce widget_black_studio_tinymce panel-first-child panel-last-child" id="panel-5749-4-0-0" data-index={5}>
                            <div className="textwidget">
                                <p>Do grona naszych klientów należą między innymi:</p>
                                <ul>
                                    <li>Fabryka Farb i Lakierów Śnieżka SA Brzeźnica</li>
                                    <li>Goodyear TC Dębica</li>
                                    <li>IKEA</li>
                                    <li>VENTOR Sp Z o.o. Dębica</li>
                                    <li>KRONOSPAN HPL Pustków</li>
                                    <li>BRENNTAG Kędzierzyn Koźle</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="panel-grid-cell" id="pgc-5749-4-1">
                        <div className="so-panel widget widget_black-studio-tinymce widget_black_studio_tinymce panel-first-child panel-last-child" id="panel-5749-4-1-0" data-index={6}>
                            <div className="textwidget">
                                <p><br /></p>
                                <ul>
                                    <li>BDI Poland Straszęcin
                                    </li>
                                    <li>UTC UMCS Technologies (dawne KIDDE) Ropczyce</li>
                                    <li>KAZET Pustków</li>
                                    <li>OMEGA International Transport and Spedition - Pilzno</li>
                                    <li>Delta Dębica</li>
                                    <li>BP Consulting Sieradz</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-grid" id="klienci-2">
                    <div className="siteorigin-panels-stretch panel-row-style"
                         style={{padding: '30px 0px 100px', backgroundColor: '#ffffff'}} data-stretch-type="full">
                        <div className="panel-grid-cell" id="pgc-5749-klienci-2-0">
                            <div className="so-panel widget widget_gallery-images panel-first-child panel-last-child"
                                 id="panel-5749-5-0-0" data-index={7}>
                                <div className="thim-widget-gallery-images thim-widget-gallery-images-base">
                                    <div className="thim-carousel-wrapper gallery-img " data-visible={6}
                                         data-itemtablet={3} data-itemmobile={2} data-pagination={0}>
                                        <div className="item"><img src={cl1} width={150}
                                                                   height={150} alt=""/></div>
                                        <div className="item"><img src={cl2} width={150}
                                                                   height={150} alt=""/></div>
                                        <div className="item"><img src={cl3} width={150}
                                                                   height={150} alt=""/></div>
                                        <div className="item"><img src={cl4} width={150}
                                                                   height={150} alt=""/></div>
                                        <div className="item"><img src={cl6} width={150}
                                                                   height={150} alt=""/></div>
                                        <div className="item"><img src={cl7} width={150}
                                                                   height={150} alt=""/></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-grid" id="referencje">
                    <div className="siteorigin-panels-stretch panel-row-style"
                         style={{marginBottom: '-30px', padding: '30px 0px 100px', backgroundColor: '#ffffff'}}
                         data-stretch-type="full">
                        <div className="panel-grid-cell" id="pgc-5749-referencje-0">
                            <div className="so-panel widget widget_heading panel-first-child" id="panel-5749-6-0-0"
                                 data-index={8}>
                                <div className="thim-widget-heading thim-widget-heading-base">
                                    <div className="sc_heading text-center">
                                        <h3 className="title">Referencje</h3>
                                        <p className="sub-heading" style={{}}>Nasi klienci o współpracy z
                                            nami</p><span className="line"/>
                                    </div>
                                </div>
                            </div>
                            <div className="so-panel widget widget_gallery-images panel-last-child"
                                 id="panel-5749-6-0-1" data-index={9}>
                                <div className="thim-widget-gallery-images thim-widget-gallery-images-base">
                                    <div className="thim-carousel-wrapper gallery-img " data-visible={5}
                                         data-itemtablet={2} data-itemmobile={1} data-pagination={1}>
                                        <div className="item"><a target="_blank"
                                                                 href={goodYearRef}
                                                                 rel="lightbox[5749]"><img
                                            src={goodYearRef} width={212}
                                            height={300} alt=""/></a></div>
                                        <div className="item"><a target="_blank"
                                                                 href={ref1}
                                                                 rel="lightbox[5749]"><img
                                            src={ref1} width={212} height={300}
                                            alt=""/></a></div>
                                        <div className="item"><a target="_blank"
                                                                 href={ref2}
                                                                 rel="lightbox[5749]"><img
                                            src={ref2} width={211} height={300}
                                            alt=""/></a></div>
                                        <div className="item"><a target="_blank"
                                                                 href={ref3}
                                                                 rel="lightbox[5749]"><img
                                            src={ref3} width={211} height={300}
                                            alt=""/></a></div>
                                        <div className="item"><a target="_blank"
                                                                 href={ref4}
                                                                 rel="lightbox[5749]"><img
                                            src={ref4} width={214} height={300}
                                            alt=""/></a></div>
                                        <div className="item"><a target="_blank"
                                                                 href={ref5}
                                                                 rel="lightbox[5749]"><img
                                            src={ref5} width={212} height={300}
                                            alt=""/></a></div>
                                        <div className="item"><a target="_blank"
                                                                 href={ref6}
                                                                 rel="lightbox[5749]"><img
                                            src={ref6} width={215} height={300}
                                            alt=""/></a></div>
                                        <div className="item"><a target="_blank"
                                                                 href={ref7}
                                                                 rel="lightbox[5749]"><img
                                            src={ref7} width={215} height={300}
                                            alt=""/></a></div>
                                        <div className="item"><a target="_blank"
                                                                 href={refSniezka}
                                                                 rel="lightbox[5749]"><img
                                            src={refSniezka} width={212}
                                            height={300} alt=""/></a></div>
                                        <div className="item"><a target="_blank"
                                                                 href={refIkea}
                                                                 rel="lightbox[5749]"><img
                                            src={refIkea} width={212}
                                            height={300} alt=""/></a></div>
                                        <div className="item"><a target="_blank"
                                                                 href={refCCI}
                                                                 rel="lightbox[5749]"><img
                                            src={refCCI} width={211}
                                            height={300} alt=""/></a></div>
                                        <div className="item"><a target="_blank"
                                                                 href={refG2A}
                                                                 rel="lightbox[5749]"><img
                                            src={refG2A} width={232}
                                            height={300} alt=""/></a></div>
                                        <div className="item"><a target="_blank"
                                                                 href={refRomet}
                                                                 rel="lightbox[5749]"><img
                                            src={refRomet} width={212} height={300}
                                            alt=""/></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;